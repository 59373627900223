import React from "react";
import logo from "../../assets/logo.png";
import sris_img from "../../assets/sris2.jpg";
import sris_sound from "../../assets/Space-Riot-In-Space.mp3";
import triangle_img from "../../assets/skull-0.1.jpg";
import triangle_sound from "../../assets/TRIANGLES.mp3";
import g1 from "../../assets/g1.jpeg";
import g2 from "../../assets/g2.jpeg";
import g3 from "../../assets/g3.jpeg";
import r1 from "../../assets/r1.jpeg";
import r2 from "../../assets/r2.jpeg";
import r3 from "../../assets/r3.jpeg";
import m1 from "../../assets/m1.jpeg";
import m2 from "../../assets/m2.jpeg";
import m3 from "../../assets/m3.jpeg";
import m4 from "../../assets/m4.jpeg";
import "./home.css";

const body =
  "mailto:kontakt@riotsaurusrex.de?subject=Ich möchte ein T-Shirt von Riotsaurus Rex&body=Ich möchte das Motiv XY in der Farbe XY und der Größe XY";

function Home() {
  return (
    <div>
      <img src={logo} alt="Riot Saurus Rex"></img>

      <p>
        ROOOOAAAARRRRR! Das beschreibt so ziemlich das, was man am liebsten bei
        innerer Unruhe ausbrüllen möchte… egal ob es ein blöder Tag auf Arbeit
        war, ein intelektuell ausbaufähiger Politiker etwas postuliert oder dir
        deine Tasse Kakao ausgekippt ist, irgendwas ist ja immer!
      </p>

      <div className="text-column">
        <p>
          Und um genau dieses Druckventil zu öffnen sind wir da! Wir sind drei
          grandios-virtuose Musiker/Künstler, die dieser Emotion in einer
          sinnlich gelenkten Bahn freien Lauf lassen wollen 🤟
        </p>
        <p>
          Passend dazu gibt es geile Mucke*, die die Gemüter befriedigt und
          oberaffentittengeiles Merch in unserem Shop – fairtrade und bio
          natürlich!
        </p>
      </div>

      <h2>Wat muckt dat muckt!</h2>
      <div className="musik">
        <div className="songAndCover">
          <h3>Space Riot in Space</h3>
          <img src={sris_img} alt="Space Riot In Space cover"></img>
          <audio controls preload="auto">
            <source src={sris_sound} type="audio/mp3"></source>
          </audio>
        </div>
        <div className="songAndCover">
          <img src={triangle_img} alt="triangle cover"></img>
          <audio controls preload="auto">
            <source src={triangle_sound} type="audio/mp3"></source>
          </audio>
        </div>
      </div>

      <h2>Loot</h2>
      <p>
        Wenn Du auch ein Shirt willst, nutze einfach das Kontaktformular und
        schreibe uns deine Adresse, deine Größe und Wunschfarbe/-motiv.
      </p>
      <p>
        Wir sammeln erstmal genug Bestellungen zusammen und geben dann den
        Druckauftrag frei – nur so können wir saurierstarke Preise anbieten
        (Mengenrabatt ahu!) 🦖 Sobald wir den Auftrag aufgeben können, melden
        wir uns nochmal bei dir bzgl. der Bezahlung und kurze Zeit später wirst
        du 1 nices Shirt in den Händen halten! ROARRR!
      </p>

      <h2>Loot aus unserem Shop</h2>
      <div className="galerie">
        <img src={g1}></img>
        <img src={g2}></img>
        <img src={g3}></img>
      </div>

      <h2>
        Bestell hier – schreibe uns eine Mail mit deiner Größe und Wunschfarbe.
      </h2>
      <div className="sendMail">
        <a href={body}>Sende Email</a>
      </div>

      <h2>Leben im Riot</h2>
      <div className="galerie">
        <img src={r1}></img>
        <img src={r3}></img>
        <img src={r2}></img>
      </div>

      <h2>Urzeitliches Material</h2>
      <div className="galerie">
        <img src={m1}></img>
        <img src={m3}></img>
        <img src={m2}></img>
        <img src={m4}></img>
      </div>
    </div>
  );
}

export default Home;
